import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import Config from 'Config';
import PageErrorBoundary from './PageErrorBoundary';
import { Box, Typography } from '@mui/material';
import { Hourglass } from 'react-loader-spinner';

import '../utils/UtilsBasename';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const Login = lazy(() => import('./login/Login'));
const Market = lazy(() => import('./market/Market'));
const MarketOutside = lazy(() => import('./marketOutside/Market'))
const Admin = lazy(() => import('./admin/Admin'));
const Verify = lazy(() => import('./verify/Verify'));
const AppStores = lazy(() => import('./appStores/AppStores'))
const Menu = lazy(() => import('./menu/Menu'))
const Loading = () => <Box sx={{ width: 1, height: "100vh" }} display='flex' alignItems='center' justifyContent='center'>
            <Hourglass
                visible={true}
                height="80"
                width="80"
                colors={['rgb(212, 0, 0)', 'rgb(255,140,0)']}
            />
</Box>;
const NotFound = () => <Box sx={{ width: 1, height: "100vh" }} display='flex' alignItems='center' justifyContent='center'>
<Rings
  height="80"
  width="80"
  color="rgb(212, 0, 0)"
  radius="6"
  visible={true}
/>
<Typography>oh no ... page is not found</Typography>
</Box>;

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(212, 0, 0)',
        },
        secondary: {
            main: 'rgb(255,255,255)',
        }
      },
  });  

const App = () => (
    <ThemeProvider theme={theme}>
        <Router basename={Config.BASENAME}>
            <PageErrorBoundary>
                <Suspense fallback={<Loading />}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/market" replace />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/market' element={<Market />} />
                        <Route path='/market_outside' element={<MarketOutside />} />
                        <Route path='/admin' element={<Admin />} />
                        <Route path='/verify' element={<Verify />} />
                        <Route path='/app_stores' element={<AppStores />} />
                        <Route path='/menu' element={<Menu />} />
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </Suspense>
            </PageErrorBoundary>
        </Router>
    </ThemeProvider>
);


export default App