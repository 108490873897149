import {
    GET_JWT_SUCCESS,
    REFRESH_JWT_SUCCESS,
    DELETE_JWT
} from '../action/JwtAction';

const jwt = (state = {}, action) => {
    switch (action.type) {
        case GET_JWT_SUCCESS:
            return action.payload;
        case REFRESH_JWT_SUCCESS:
            return action.payload
        case DELETE_JWT:
            return {}
        default:
            return state
    }
};


export default jwt