import React, {Component} from 'react';
import { Box, Typography } from '@mui/material';
import { Rings } from 'react-loader-spinner';


class PageErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error: error
        };
    }

    render() {
        const { hasError, error } = this.state;

        if (hasError) {
            console.error(error);
            return <Box sx={{ width: 1, height: "100vh" }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Rings
              height="300"
              width="300"
              color="rgb(212, 0, 0)"
              radius="6"
              visible={true}
            />
            <Typography variant='h2'>oh no ... page was not found :(</Typography>
            </Box>;
        }

        return this.props.children;
    }
}


export default PageErrorBoundary;