import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import initializeStore from './redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/App';


const store = initializeStore();

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={store.persistor}>
            <App />
        </PersistGate>
    </Provider>
);