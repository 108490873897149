import Config from 'Config';


const handleResponse = response => {
    let contentType = response.headers.get('content-type');
    if (contentType.includes('application/json')) {
        return handleJSONResponse(response);
    } else if (contentType.includes('text/plain')) {
        return handleTextResponse(response);
    } else {
        throw new Error(`Content-type [${contentType}] is not supported`);
    }
};


const handleJSONResponse = response => (
    response.json()
        .then(json => (response.ok)
            ? json
            : Promise.reject({
                status: response.status,
                statusText: response.statusText,
                err: (json.error) ? json.error : json
            })
        )
);


const handleTextResponse = response => (
    response.text()
        .then(text => (response.ok)
            ? text
            : Promise.reject({
                status: response.status,
                statusText: response.statusText,
                err: text
            })
        )
);


const apiCall = (endpoint, method, body = {}, successCallback, errorCallback, state) => {
    const params = (method !== 'GET')
        ? {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': state.jwt.token && !endpoint.includes("/auth") ? "Bearer " + state.jwt.token : null
            },
            body: JSON.stringify(body)
        }
        : {
            headers: {
                'Authorization': state.jwt.token ? "Bearer " + state.jwt.token : null
            }
        };

    return fetch(Config.API_URL + endpoint, params)
        .then(response => handleResponse(response))
        .then(data => successCallback(data))
        .catch(error => errorCallback(error))
};


export default apiCall