/*
 * action types
 */
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';


/*
 * action creators
 */
export const addToast = options => ({ type: ADD_TOAST, payload: options });
export const removeToast = id => ({ type: REMOVE_TOAST, payload: id });