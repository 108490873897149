import React from 'react';


/*
 * action types
 */
export const GET_JWT_API = 'GET_JWT_API';
export const GET_JWT_SUCCESS = 'GET_JWT_SUCCESS';
export const GET_JWT_ERROR = 'GET_JWT_ERROR';

export const REFRESH_JWT_API = 'REFRESH_JWT_API';
export const REFRESH_JWT_SUCCESS = 'REFRESH_JWT_SUCCESS';
export const REFRESH_JWT_ERROR = 'REFRESH_JWT_ERROR';

export const DELETE_JWT = 'DELETE_JWT';

/*
 * action creators
 */
export const getJwt = body => dispatch => (
    dispatch({
        type: [
            GET_JWT_API,
            GET_JWT_SUCCESS,
            GET_JWT_ERROR
        ],
        payload: {
            endpoint: '/auth/sign-in',
            method: 'POST',
            body
        }
    })
);

export const refreshJwt = body => dispatch => (
    dispatch({
      type: [
          REFRESH_JWT_API,
          REFRESH_JWT_SUCCESS, 
          REFRESH_JWT_ERROR
      ],
      payload: {
          endpoint: '/auth/refresh',
          method: 'POST',
          body
      }
    })
  );
  
  export const deleteJwt = body => (
    {type: DELETE_JWT, body}
  );